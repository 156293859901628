import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import Header from "./header";

const HeaderWhiteBackgroundImage = ({ src, icon, title, description }) => {
  return (
    <div className="font-Montserrat">
      <div className="relative top-0 z-50 w-full px-12 py-2 bg-white shadow-lg nav xl:pt-4 ">
        <Header className="" />
      </div>
      <section className="relative w-full backgroundImage">
        <img
          data-src={src}
          alt="belowheaderimage"
          className=" w-full lazyload h-[44vh] md:h-[55vh] lg:h-[60vh] xl:h-[75vh]"
        />
      </section>
      <section className="absolute top-16 md:top-16 md:left-24 xl:top-[4rem] xl:left-[10rem] overlayDescription lg:top-[6rem]">
        <div className="pl-4">
          <section className="flex items-center font-medium xl:py-2  ">
            {icon}
            <div>
              <section className=" xl:justify-center xl:max-w-max md:px-4">
                <p className="pt-2 font-medium tracking-tight text-whitesmoke xl:text-5xl md:text-3xl xl:pt-12 ">
                  {title}
                </p>
                <div className="flex py-1 jsutfy-start xl:justify-center xl:py-4">
                  <div className="h-0.5 rounded w-24 md:w-24 xl:w-44 bg-gold font-"></div>
                </div>
              </section>
              <p className="text-xs font-thin tracking-tight w-80 md:w-96 text-whitesmoke xl:pr-2 xl:tracking-wider xl:w-96 xl:text-md md:px-4 md:tracking-wider md:text-medium ">
                {description}
              </p>
            </div>
          </section>
        </div>
      </section>
      {/* </div> */}
    </div>
  );
};

export default HeaderWhiteBackgroundImage;
