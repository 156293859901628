// FAQ.js
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid"; // Import arrow icons
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { useState } from "react";
import ContactUs from "../Components/contact-us";
import Footer from "../Components/footer";
import Header from "../Components/header";
import serviceHeaderImage1 from "../asset/Group 16faq.jpg";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null); // State to keep track of open question index

  const faqs = [
    {
      question: "Who are Nidigit Solution experts?",
      answer:
        "We are a group of computer scientists working on machine problems, serving both customers and small to big-sized businesses in Nigeria.",
    },
    {
      question:
        "I am extremely interested in Nidigit Solution services. Are there any consulting fees?",
      answer:
        "We are happy to share to you that there’s no initial consultation fee. Feel free to tell us your needs.",
    },
    {
      question: "What are the basic features of cloud computing?",
      answer: `Some of the major characteristics of a cloud computing model include:

      Availability and resilience
      Ubiquitous network
      Scalability and elasticity
      Standardized interfaces
      Self-service provisioning and automatic de-provisioning
      Billing on self-service based usage`,
    },
    {
      question: "What are the benefits of cloud computing?",
      answer: `Remember that cloud computing minimizes IT operational costs, as the cloud provider handles the underlying infrastructure, such as software and hardware. Those managed components are normally more secure and reliable than the average corporate data center. Those benefits free IT teams from concentrating on work, which is more directly benefits the business.

      The cloud is also convenient, global, substantially scalable, and easily available, increasing the time to make and deploy software applications. It opens up the organization to a host of newer services that allow the most famous trends in application architectures and uses, such as serverless computing, containers, microservices, machine learning, IoT, big-scale data analytics, and so much more."
    `,
    },
    {
      question: "Is the cloud secure?",
      answer: `Keep in mind that the cloud is normally more secure than the many private data centers, as companies such as Google, Amazon, and Microsoft can hire expert engineers and power most of their practices. Moreover, cloud infrastructure providers provide architectural options and tools to isolate workloads, detect possible threats and encrypt data.

    Nonetheless, public clouds run on a shared responsibility model, where a user secures the applications and data hosted on the cloud. The division of security responsibilities differs based on the cloud computing’s tier.
    
    The process to secure a certain cloud environment is very much different from more average data center practices. Thus, cloud adoption needs a learning curve for IT teams. Moreover, unauthorized access to resources is the most typical cloud security threat, as most high-profile exposures of sensitive data lead to misconfigurations.
    
    Businesses and organizations should be extra cautious of data residency requirements and other governance restrictions. They do not have exact control over the servers’ location that hosts their data within the cloud.`,
    },
    {
      question: "How can I prepare my business for the cloud?",
      answer: `It could take over a year to perform a cloud migration strategy. Even then, it is a continuous process. Communication is vital to success. Keep your stakeholders informed, and ensure IT is part of the wider decision-making process for your business.

      Determine leaders who will evangelize the cloud usage and guarantee employees and staff are properly trained for the entire transition.",
   `,
    },
    {
      question: "How much does the cloud cost?",
      answer: `Take note that public clouds are charged on a per-use basis. Thus, the costs will differ massively based on numerous variables, such as the region you operate in, the provider, the size of the environment, the amount of data movement, and the number of high-level services used.

    The main public cloud providers also have unique pricing programs that can reduce costs in exchange for specific long-term commitments.
    
    That is a huge debate about whether the cloud is cheaper or costlier than standard on-premises computing. However, that misses the entire point. The cloud could help modernize an enterprise and its entire IT department and move IT from its physical role as a gatekeeper into a crucial participant in the decision-making process for the enterprise’s business side.`,
    },
    // Add more questions and answers as needed
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the openIndex state
  };

  return (
    <div className="font-Montserrat ">
      <div className="relative top-0 z-50 w-full px-12 py-2 bg-white shadow-lg nav xl:pt-4">
        <Header className="" />
      </div>
      <section className="relative w-full backgroundImage">
        <img
          data-src={serviceHeaderImage1}
          alt="belowheaderimage"
          className="w-full h-[44vh] md:h-[55vh] xl:h-[75vh] lazyload"
        />
      </section>
      <section className="absolute top-16 md:top-18 md:left-24  xl:top-[3rem] xl:left-[10rem] overlayDescription lg:top-[4rem]">
        <div className="">
          <section className="flex items-center font-medium xl:py-2 ">
            {/* {icon} */}
            <div>
              <section className="px-5 pt-2 xl:justify-center xl:max-w-max md:px-4">
                <p className="pt-2 font-medium tracking-tight text-whitesmoke xl:text-5xl md:text-3xl xl:pt-12 ">
                  FAQ
                </p>
                <div className="flex py-1 jsutfy-start xl:justify-center xl:py-4">
                  <div className="h-0.5 rounded w-8 md:w-24 xl:w-44 bg-gold font-"></div>
                </div>
              </section>
              <p className="px-5 text-xs font-thin tracking-tight w-80 md:w-96 text-whitesmoke xl:pr-2 xl:tracking-wider xl:w-96 xl:text-md md:px-4 md:tracking-wider md:text-medium">
                Do you have questions in mind you want to be answered? Below are
                the common questions our clients ask us.
              </p>
            </div>
          </section>
        </div>
      </section>

      <div className="relative flex justify-center w-full mx-auto top-[-6rem] md:top-[-8rem]  lg:top-[-8rem] xl:top-[-15rem]">
        <div className="w-[90%]  ">
          <h1 className="mb-4 text-xl font-semibold text-center lg:text-3xl">
            Frequently Asked Questions
          </h1>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="p-4 transition-shadow bg-[#FCFCFC] rounded-lg shadow-xl my-7 py-7"
              >
                <div
                  className="flex items-center justify-start transition ease-in-out delay-150 cursor-pointer h-"
                  onClick={() => toggleFAQ(index)}
                >
                  {openIndex === index ? (
                    <ChevronUpIcon className="w-4 h-4 text-gray-600" />
                  ) : (
                    <ChevronDownIcon className="w-4 h-4 text-gray-600" />
                  )}
                  <h2 className="px-2 text-sm tracking-tight ">
                    {faq.question}
                  </h2>
                </div>
                {openIndex === index && (
                  <p className="px-5 py-5 mt-2 overflow-hidden text-sm text-gray-600 transition-all duration-300 ease-in-out ">
                    {faq.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative top-[-4rem] mt-12 contact md:top-[0rem] lg:top-[-8rem]">
        <ContactUs />
      </div>
      <div className=" relative top-[-3rem] footer md:top-[0rem] lg:top-[0rem]">
        <Footer />
      </div>
    </div>
  );
};

export default FAQ;
