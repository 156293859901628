import HeaderWhiteBackgroundImage from "../Components/HeaderWhiteBackgroundImage";
import Card from "../Components/card";
import Footer from "../Components/footer";
import WhyWorkWithUs from "../Components/whyWorkWithUs";
import serviceHeaderImage1 from "../asset/Group 16itoutsourcing.png";
import { ReactComponent as Vectorservice1 } from "../asset/Vectorservice (1).svg";
import { ReactComponent as Vectorservice2 } from "../asset/Vectorservice (2).svg";
import { ReactComponent as Vectorservice3 } from "../asset/Vectorservice (3).svg";
import { ReactComponent as IconParkLight } from "../asset/cfluent-mdl2_recruitment-management.svg";
import { ReactComponent as Undraw } from "../asset/undraw_updated_resume_re_7r9j 1.svg";

import { ReactComponent as UndrawEngineers } from "../asset/undraw_interview_re_e5jn 1.svg";

const ITOutsourcing = () => {
  return (
    <div className="z-50 overflow-hidden font-Montserrat ">
      <HeaderWhiteBackgroundImage
        src={serviceHeaderImage1}
        icon={<IconParkLight />}
        title={"IT Outsourcing & Recruitment"}
        description={
          "Embrace our expertise to streamline IT functions while you focus on core business goals."
        }
      />
      <div className="absolute top-[13rem] w-full h-max-content"></div>
      <Vectorservice1 className="absolute right-0 top-[32rem] w-4/5 max-w-md  lg:top-[23rem] xl:top-[34rem] xl:right-0 " />

      <Vectorservice2 className="absolute top-0 w-1/2 md:top-[10rem]  max-w-md lg:top-[15rem] xl:top-[20rem] z-0 " />
      <Vectorservice3 className="absolute left-0 w-3/4 top-[70rem] max-w-md md:top-[55rem] lg:top-[40rem] xl:top-[60rem]" />

      <div className="absolute top-[18rem]  flex justify-center md:top-[24rem] lg:top-[30rem] md:justify-start md:pl-24 lg:pl-14 xl:top-[35rem] xl:pl-36">
        <Card>
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            With comprehensive reach to IT professionals, subject matter
            experts, and our inclusive array of services, Nidigit Solution can
            ensure your IT need are not only met but exceeded. We work closely
            along with our clients to establish a set of personalized IT
            services and systems that are smoothly integrated, sustainable and
            efficient for many years to come. We have been supporting our
            customers along with their IT outsourcing and recruitment
            challenges.
          </p>
        </Card>
      </div>
      <div className="lg:flex lg:justify-end">
        <Undraw className="absolute  w-3/4 md:w-2/3 lg:w-1/3 top-[46rem] md:top-[42rem] md:right-[20rem] lg:top-[32rem] xl:top-[35rem]  lg:right-16 " />
      </div>

      <div className="absolute top-[68rem]  flex justify-center md:top-[85rem] lg:top-[58rem] lg:justify-end lg:pr-24 xl:top-[60rem] xl:pr-32">
        <Card className="">
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            Finding the ideal recruit or developer for your business is not as
            easy as it once was. We have been around many years recruiting top
            notch IT companies and for the IT sector, and our reach to IT
            candidates in Nigeria is massive. We get to know our clients inside
            and out, learn their business and culture for a hiring fit that not
            just mirrors key skills and talents but also cultural drive. The
            specialized nature of the organization and our specialized
            recruitment consultants in every field of IT make a more attentive
            experience, resulting in a higher chance of finding the perfect
            talent in today’s competitive market.
          </p>
        </Card>
      </div>
      <div className="absolute top-[99rem] w-3/4 left-8 md:top-[68rem] md:left-[22rem] max-w-md lg:top-[50rem] lg:left-0 lg:pl-32 xl:top-[66rem]">
        <UndrawEngineers className="lg:w-3/4 xl:w-full" />
      </div>

      <div className="absolute top-[120rem] w-full md:top-[100rem]  md:flex md:justify-center lg:top-[75rem] xl:top-[89rem]">
        <WhyWorkWithUs />
      </div>
      <div className="absolute top-[142rem] md:top-[116rem]  w-full lg:top-[90rem] xl:top-[99rem]">
        <Footer />
      </div>
    </div>
  );
};

export default ITOutsourcing;
