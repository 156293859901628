import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Views/about";
import CapacityBuild from "./Views/capacity_building";
import CloudComputing from "./Views/cloud_computing";
import Contact_us from "./Views/contact_us";
import FAQ from "./Views/faq";
import Homepage from "./Views/homepage";
import IT_ASSURANCE from "./Views/it_assurances";
import MobilityService from "./Views/mobility_services";
import ITOutsourcing from "./Views/ot_outsourcing";
import TechBusiness from "./Views/tech_business";
// import DropdownList from "./Views/test";
import NavBar from "./Views/test";

function App() {
  return (
    // <div className="pb-0 font-mmontserrat">
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/services/it-assurance" element={<IT_ASSURANCE />} />
        <Route path="/services/capacity-building" element={<CapacityBuild />} />
        <Route
          path="/services/mobility-service"
          element={<MobilityService />}
        />
        <Route path="/services/it-outsourcing" element={<ITOutsourcing />} />
        <Route path="/services/tech-business" element={<TechBusiness />} />
        <Route path="/services/cloud-computing" element={<CloudComputing />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/contact" element={<Contact_us />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/dropdown" element={<DropdownList />} /> */}
        <Route path="/tests" element={<NavBar />} />
      </Routes>
    </BrowserRouter>

    // </div>
  );
}

export default App;
