import { ReactComponent as Check } from "../asset/mingcute_check-fill.svg";

const WhyWorkWithUs = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full py-8 text-white rounded-lg bg-primary md:w-3/4 ">
      <h1 className="py-4">Why Work with us</h1>
      <div className="flex flex-col items-center justify-center w-full lg:grid lg:grid-cols-2 place-items-center place-content-center">
        <div className="flex items-center py-2 ">
          <Check /> <p className="px-2 ">Information protection</p>
        </div>
        <div className="flex items-center py-2 ">
          <Check />{" "}
          <p className="px-2 tracking-tighter ">Lowered zero false positives</p>
        </div>
        <div className="flex items-center py-2 ">
          <Check />{" "}
          <p className="px-2 tracking-tighter">
            Adherence to industry frameworks and standards
          </p>
        </div>

        <div className="flex items-center py-2 ">
          <Check />{" "}
          <p className="px-2 ">Certified and highly skilled resources</p>
        </div>
      </div>
    </div>
  );
};

export default WhyWorkWithUs;
