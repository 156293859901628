import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import Footer from "../Components/footer";
import Header from "../Components/header";
import coreValue from "../asset/Group 10.png";
import serviceHeaderImage1 from "../asset/Group16.png";
import { ReactComponent as LiveHelp } from "../asset/Live help icon.svg";
import { ReactComponent as Maintenance } from "../asset/Maintenance icon.svg";
import { ReactComponent as ProtectiveIcon } from "../asset/Proective Icon.svg";
import peopleoffice from "../asset/people-office-work-day 1.png";
import personoffice from "../asset/person-office-work-day 1.png";
import sidemanview from "../asset/side-view-men-working-together 1.png";

const About = () => {
  return (
    <div className="font-Montserrat">
      <div className="relative top-0 z-50 w-full px-12 py-2 bg-white shadow-lg nav xl:pt-4">
        <Header className="" />
      </div>
      <section className="relative w-full mt-12 backgroundImage">
        <img
          data-src={serviceHeaderImage1}
          alt="belowheaderimage"
          className="w-full lazyload xl:h-[75vh]"
        />
      </section>
      <section className=" absolute top-16 md:top-24 md:left-24 xl:top-[8rem] xl:left-[10rem] overlayDescription lg:top-[10rem]">
        <div className="">
          <section className="flex items-center font-medium xl:py-2 ">
            <div>
              <section className="px-6 xl:justify-center xl:max-w-max md:px-4">
                <p className="pt-2 font-medium tracking-tight text-whitesmoke xl:text-5xl md:text-3xl xl:p ">
                  About Us
                </p>
                <div className="flex py-1 jsutfy-start xl:justify-center xl:py-4">
                  <div className="h-0.5 rounded w-16 md:w-24 xl:w-44 bg-gold font-"></div>
                </div>
              </section>
              <p className="px-6 text-xs font-thin tracking-tight w-80 md:w-96 text-whitesmoke xl:pr-2 xl:tracking-wider xl:w-[28rem] xl:text-lg md:px-4 md:tracking-wider md:text-medium">
                We empower innovations through technology solutions.
              </p>
            </div>
          </section>
        </div>
      </section>
      <div className="">
        <div className="relative w-5/6 md:w-[90%] text-sm mx-auto about_main top-[-5rem] md:top-[-12rem] lg:top-[-12rem] xl:w-[80vw] ">
          <section className=" md:py-8 md:flex md:justify-between">
            <div className="md:flex-1">
              <p className="px-2 py-8 tracking-widest">
                Here at Nidigit Solution, we genuinely believe in our
                process-oriented approach to IT, offering every client industry
                best practices for security, disaster recovery, and
                productivity. We strive to offer our clients the security, peace
                of mind, and expertise required to make the most out of their IT
                infrastructure in a way that no other managed service provider
                can. We are continually auditing our approach to guarantee we
                offer to deliver our clients with the best services possible,
                looking at IT from a business perspective and not a technical
                one.
              </p>
            </div>
            <div className="md:flex-1">
              <img
                data-src={peopleoffice}
                alt="peopleoffice"
                className="w-full md:h-full lazyload"
              />
            </div>
          </section>
          <section className=" md:py-8 md:flex md:justify-between">
            <div className="md:flex-1">
              <img
                data-src={sidemanview}
                alt="peopleoffice"
                className="w-full md:h-[full] lazyload"
              />
            </div>
            <div className="md:flex-1">
              <p className="px-2 py-8 tracking-widest">
                Our professionals are passionate about making the next
                generation of advanced cloud-native microservices with Google,
                Microsoft, and Amazon for cost avoidance, efficiency, agility,
                and high-security regulatory compliance. We believe in the
                transformative power of agile collaboration and top-notch
                architecture to bring applications elevated beyond expectations.
              </p>
            </div>
          </section>
          <section className=" md:py-8 md:flex md:justify-between">
            <div className="md:flex-1">
              <p className="px-2 py-8 tracking-widest">
                Powerful client relationships and creative collaborations lead
                to great strategy. We are thrilled to start a conversation with
                you, learn about your goals, and create the platform for your
                strategic fruition. We have been proudly serving clients for
                many years, offering IT services, cloud solutions, and
                technology business consulting to small and medium-sized
                businesses. We take pride in presenting enterprise-level IT
                practices and advanced solutions that help these organizations
                succeed. Our experience has enabled us to create and build the
                ideal cloud workspace that offers our clients a total cloud
                solution at a price that every small business can afford.
              </p>
            </div>
            <div className="md:flex-1">
              <img
                data-src={personoffice}
                alt="peopleoffice"
                className="w-full md:h-full lazyload"
              />
            </div>
          </section>
        </div>
      </div>

      <div className="relative md:top-[-2rem]">
        <div className="bg-secondary-100 lg:py-12">
          <section className="w-5/6 mx-auto">
            <section className="py-3 text-center">
              <h5 className="text-xl">Our Vision</h5>
              <div className="flex justify-center py-1 xl:justify-center xl:py-4">
                <div className="h-0.5 rounded w-16 md:w-24 xl:w-44 bg-gold font-"></div>
              </div>
            </section>
            <section>
              <p className="py-6 tracking-wide">
                We envision becoming the leading partner in IT and cloud
                computing services. We strive to help businesses operate
                efficiently and create more value. We conceptualize and realize
                technology-driven business transformation initiatives for them.
              </p>
            </section>
          </section>
        </div>
        <div className="">
          <section className="w-5/6 py-8 mx-auto">
            <section className="py-3 text-center">
              <h5 className="text-xl">Nidigit Solution Ensures:</h5>
              <div className="flex justify-center py-1 xl:justify-center xl:py-4">
                <div className="h-0.5 rounded w-16 md:w-24 xl:w-44 bg-gold font-"></div>
              </div>
            </section>
            <section className="md:flex md:w-full">
              <div className="flex flex-col items-center flex-1 py-6">
                <div>
                  <ProtectiveIcon />
                </div>

                <h4 className="py-2">Protective care</h4>
                <p className="w-5/6 text-sm tracking-wide text-center ">
                  Commitment to the prevention of data loss and other businesses
                  technology problems before they occur.
                </p>
              </div>
              <div className="flex flex-col items-center flex-1 py-6">
                <div>
                  <LiveHelp />
                </div>

                <h4 className="py-2">Live help desk support</h4>
                <p className="w-5/6 text-sm tracking-wide text-center">
                  Reliable and fast cloud solutions 24/7
                </p>
              </div>
              <div className="flex flex-col items-center flex-1 py-6">
                <div>
                  <Maintenance />
                </div>

                <h4 className="py-2">Round the clock maintenance</h4>
                <p className="w-5/6 text-sm tracking-wide text-center">
                  24/7 access to remote service remediation services at a flat
                  rate
                </p>
              </div>
            </section>
          </section>
        </div>
        <div>
          <section className="bg-primary">
            <section className="py-8 text-white">
              <div className="py-12 title">
                <h5 className="text-xl text-center md:text-2xl">
                  Our Core Value
                </h5>
                <div className="flex justify-center py-1 xl:justify-center xl:py-4">
                  <div className="h-0.5 rounded w-16 md:w-24 xl:w-44 bg-gold font-"></div>
                </div>
              </div>
              <div className=" md:flex">
                <section className="md:flex-1">
                  <div className="flex flex-col items-center py-6">
                    <h4 className="py-2">Diversity</h4>
                    <p className="w-4/6 text-sm tracking-wide">
                      We nurture diversity and valuing unique perspectives and
                      contributions. We strive to make a respectful and
                      inclusive workspace.
                    </p>
                  </div>
                  <div className="flex flex-col items-center py-6">
                    <h4 className="py-2">Responsibility</h4>
                    <p className="w-4/6 text-sm tracking-wide">
                      We take the responsibility to drive change and encourage
                      transformation..
                    </p>
                  </div>
                </section>
                <section className="flex justify-center md:flex-1">
                  <div>
                    <img
                      data-src={coreValue}
                      alt="corevalues"
                      className="md:h-full lazyload"
                    />
                  </div>
                </section>
                <section className="md:flex-1">
                  <div className="flex flex-col items-center py-6">
                    <h4 className="py-2">Integrity</h4>
                    <p className="w-4/6 text-sm tracking-wide">
                      We are honest and understand the significance of
                      accountability. We also remain true to our word and
                      establishing trust.
                    </p>
                  </div>
                  <div className="flex flex-col items-center py-6">
                    <h4 className="py-2">Sustainability</h4>
                    <p className="w-4/6 text-sm tracking-wide">
                      Change begins from within. We create quality while staying
                      aware of the environment.
                    </p>
                  </div>
                </section>
              </div>
            </section>
          </section>
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default About;
