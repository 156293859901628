import serviceHeaderImage1 from "../asset/Group14.jpg";
import { ReactComponent as Vectorservice1 } from "../asset/Vectorservice (1).svg";
import { ReactComponent as Vectorservice2 } from "../asset/Vectorservice (2).svg";
import { ReactComponent as Vectorservice3 } from "../asset/Vectorservice (3).svg";
import { ReactComponent as IconParkLight } from "../asset/icon-park-outline_protectlight.svg";
import { ReactComponent as UndrawEngineers } from "../asset/undraw_engineering_team_a7n2 1.svg";
import { ReactComponent as Undraw } from "../asset/undraw_secure_server_re_8wsq 3.svg";
import HeaderWhiteBackgroundImage from "./HeaderWhiteBackgroundImage";
import Card from "./card";
import WhyWorkWithUs from "./whyWorkWithUs";

const ServiceComponent = () => {
  return (
    <div className="z-50 overflow-hidden font-Montserrat ">
      <HeaderWhiteBackgroundImage
        src={serviceHeaderImage1}
        icon={<IconParkLight />}
        title={"IT Assurance"}
        description={
          "Empowering Your Business with Reliable IT Infrastructure and Risk Management Solutions"
        }
      />
      <div className="absolute top-[13rem] w-full h-max-content"></div>
      <Vectorservice1 className="absolute right-0 top-[32rem] w-4/5 max-w-md  lg:top-[18rem] xl:top-[34rem] xl:right-0" />

      <Vectorservice2 className="absolute top-0 w-1/2 md:top-[10rem]  max-w-md lg:top-[8rem] xl:top-[30rem] z-0" />
      <Vectorservice3 className="absolute left-0 w-3/4 top-[70rem] max-w-md md:top-[55rem] lg:top-[40rem] xl:top-[60rem]" />

      <div className="absolute top-[14rem]  flex justify-center md:top-[24rem] lg:top-[20rem] md:justify-start md:pl-24 lg:pl-14 xl:top-[32rem] xl:pl-36">
        <Card>
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 xl:tracking-widest ">
            Assessment, timely identification, and management of security risks
            connected with networks, business applications, mobile devices, and
            related technology environments allow enterprise stakeholders to
            deal with emerging threats while keeping compliance with applicable
            regulations, industry standards, and legislative requirements.
            Hence, proactive remediation of design flaws and IT security
            assurance within the business system is essential to avoid legal
            penalties, customer lawsuits, loss of reputation, and regulatory
            fines. Organizations need the best-in-class technology, technical
            specialists, and robust processes to encourage business owners to
            constantly advance and concentrate on the core business without
            compromising on security. That’s what makes IT security assurance
            essential.
          </p>
        </Card>
      </div>
      <div className="lg:flex lg:justify-end">
        <Undraw className="absolute  w-3/4 md:w-2/3 lg:w-1/3 top-[46rem] md:top-[42rem] md:right-[20rem] lg:top-[25rem] xl:top-[30rem]  lg:right-16 " />
      </div>

      <div className="absolute top-[68rem]  flex justify-center md:top-[85rem] lg:top-[58rem] lg:justify-end lg:pr-24 xl:top-[65rem] xl:pr-32">
        <Card className="">
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            IT security assurance is the foundation enterprises need to create
            for determining the trustworthiness of processes, features,
            procedures, practices, and architecture of the information system.
            Nidigit Solution’s IT assurance services help clients across a broad
            spectrum of industry verticals in identifying the compliance level
            of the technical security controls along with legislative,
            applicable regulations, and standard requirements.
          </p>
        </Card>
      </div>
      <div className="absolute top-[90rem] w-3/4 left-8 md:top-[68rem] md:left-[22rem] max-w-md lg:top-[50rem] lg:left-0 lg:pl-32 xl:top-[65rem]">
        <UndrawEngineers className="lg:w-3/4 xl:w-full " />
      </div>

      <div className="absolute top-[110rem] w-full md:top-[100rem]  md:flex md:justify-center lg:top-[75rem] xl:top-[85rem]">
        <WhyWorkWithUs />
      </div>
    </div>
  );
};

export default ServiceComponent;
