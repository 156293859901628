import HeaderWhiteBackgroundImage from "../Components/HeaderWhiteBackgroundImage";
import Card from "../Components/card";
import Footer from "../Components/footer";
import WhyWorkWithUs from "../Components/whyWorkWithUs";
import serviceHeaderImage1 from "../asset/Group 16mobility.png";
import { ReactComponent as Vectorservice1 } from "../asset/Vectorservice (1).svg";
import { ReactComponent as Vectorservice2 } from "../asset/Vectorservice (2).svg";
import { ReactComponent as Vectorservice3 } from "../asset/Vectorservice (3).svg";
import { ReactComponent as IconParkLight } from "../asset/cmaterial-symbols-light_computer-outline.svg";
import { ReactComponent as UndrawEngineers } from "../asset/undraw_conference_call_b0w6 1.svg";
import { ReactComponent as Undraw } from "../asset/undraw_work_from_anywhere_re_s2i6 1.svg";

const MobilityService = () => {
  return (
    <div className="z-50 overflow-hidden font-Montserrat ">
      <HeaderWhiteBackgroundImage
        src={serviceHeaderImage1}
        icon={<IconParkLight />}
        title={"Mobility Services"}
        description={
          "Embrace efficiency and flexibility while optimizing your digital operations for sustained success in a mobile-first world."
        }
      />
      <div className="absolute top-[13rem] w-full h-max-content"></div>
      <Vectorservice1 className="absolute right-0 top-[32rem] w-4/5 max-w-md  lg:top-[23rem] xl:top-[34rem] xl:right-0" />

      <Vectorservice2 className="absolute top-0 w-1/2 md:top-[10rem]  max-w-md lg:top-[15rem] xl:top-[30rem] z-0" />
      <Vectorservice3 className="absolute left-0 w-3/4 top-[70rem] max-w-md md:top-[55rem] lg:top-[30rem] xl:top-[60rem]" />

      <div className="absolute top-[14rem]  flex justify-center md:top-[24rem] lg:top-[30rem] md:justify-start md:pl-24 lg:pl-14 xl:top-[34rem] xl:pl-36">
        <Card>
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            Mobility is at the core of this digital world. Businesses and
            organizations today understand that they need to offer their people
            access to business applications and encourage them to work
            effortlessly from anywhere. Mobility has become a paradigm for
            engagement and efficiency, especially in the information age.
            Suppose the proper approach to mobility is strengthened by
            personalized and innovative solutions, which align smoothly with the
            organization’s workflow processes. You see, mobility has the power
            to change the way businesses are done.
          </p>
        </Card>
      </div>
      <div className="lg:flex lg:justify-end">
        <Undraw className="absolute  w-3/4 md:w-2/3 lg:w-1/3 top-[42rem] md:top-[42rem] md:right-[20rem] lg:top-[32rem] xl:top-[32rem]  lg:right-16 " />
      </div>

      <div className="absolute top-[68rem]  flex justify-center md:top-[85rem] lg:top-[58rem] lg:justify-end lg:pr-24 xl:top-[65rem] xl:pr-32">
        <Card className="">
          <p className="text-gray-700 ">
            Mobile devices are becoming widespread, and both consumers and
            enterprises are shifting to them for their information needs.
            Businesses are continuously challenged to keep pace with the
            increased proliferation of devices with numerous form factors on
            various platforms and transforming enterprise business requirements.
          </p>
        </Card>
      </div>
      <div className="absolute top-[90rem] w-3/4 left-8 md:top-[68rem] md:left-[22rem] max-w-md lg:top-[50rem] lg:left-0 lg:pl-32 xl:top-[63rem]">
        <UndrawEngineers className="lg:w-3/4 xl:w-full" />
      </div>

      <div className="absolute top-[115rem] w-full md:top-[100rem]  md:flex md:justify-center lg:top-[75rem] xl:top-[85rem]">
        <WhyWorkWithUs />
      </div>
      <div className="absolute top-[135rem] md:top-[116rem]  w-full lg:top-[90rem] xl:top-[99rem]">
        <Footer />
      </div>
    </div>
  );
};

export default MobilityService;
