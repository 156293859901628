import HeaderWhiteBackgroundImage from "../Components/HeaderWhiteBackgroundImage";
import Card from "../Components/card";
import Footer from "../Components/footer";
import WhyWorkWithUs from "../Components/whyWorkWithUs";
import { ReactComponent as Undraw } from "../asset/Group 12.svg";
import serviceHeaderImage1 from "../asset/Group 16cloud.jpg";
import { ReactComponent as Vectorservice1 } from "../asset/Vectorservice (1).svg";
import { ReactComponent as Vectorservice2 } from "../asset/Vectorservice (2).svg";
import { ReactComponent as Vectorservice3 } from "../asset/Vectorservice (3).svg";
import { ReactComponent as IconParkLight } from "../asset/cph_briefcase-light.svg";

import { ReactComponent as UndrawEngineers } from "../asset/undraw_maintenance_re_59vn 1.svg";

const CloudComputing = () => {
  return (
    <div className="z-50 overflow-hidden font-Montserrat ">
      <HeaderWhiteBackgroundImage
        src={serviceHeaderImage1}
        icon={<IconParkLight />}
        title={"Cloud Computing"}
        description={
          "Empower your business with our tailored cloud solutions, ensuring seamless access to resources and opportunities anytime, anywhere."
        }
      />
      <div className="absolute top-[13rem] w-full h-max-content"></div>
      <Vectorservice1 className="absolute right-0 top-[32rem] w-4/5 max-w-md  lg:top-[23rem] xl:top-[30rem] xl:right-0" />

      <Vectorservice2 className="absolute top-0 w-1/2 md:top-[10rem]  max-w-md lg:top-[15rem] xl:top-[20rem] z-0" />
      <Vectorservice3 className="absolute left-0 w-3/4 top-[70rem] max-w-md md:top-[55rem] lg:top-[40rem] xl:top-[52rem] " />

      <div className="absolute top-[18rem]  flex justify-center md:top-[24rem] lg:top-[30rem] md:justify-start md:pl-24 lg:pl-14 xl:top-[34rem] xl:pl-36">
        <Card>
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            Every business is a technology business, and Nidigit Solution covers
            it all. We concentrate on the business concerns you experience and
            offer technology consulting to meet your needs, from long-term
            solutions to rapid response. Our technology business consulting
            professionals will introduce you to flexible delivery schemes that
            can be easily scaled up or down through our strong partner
            ecosystem. As new technologies arrive, Nidigit Solution is always
            here to help you adapt and accelerate the adoption of the ideal
            technology for your unique business needs.
          </p>
        </Card>
      </div>
      <div className="lg:flex lg:justify-end">
        <Undraw className="absolute  w-3/4 md:w-2/3 lg:w-1/3 top-[46rem] md:top-[42rem] md:right-[20rem] lg:top-[32rem] xl:top-[30rem]  lg:right-16 " />
      </div>

      <div className="absolute top-[68rem]  flex justify-center md:top-[85rem] lg:top-[58rem] lg:justify-end lg:pr-24 xl:top-[60rem] xl:pr-32">
        <Card className="">
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            At Nidigit Solution, we will offer you Cloud Native solutions and
            help you boost your overall business performance. Throughout the
            years, we have been supporting small, medium, and large enterprises
            through our cloud computing services. Cloud-based computing has
            finally emerged as the go-to storage and data management solution
            for businesses seeking financial resources and economization through
            the internet. It’s about time you save substantial money and time
            instead of having to deal with a manual data backup process or a
            physical server or computer. The good thing about cloud computing is
            that you have the freedom to work from anywhere when your cloud data
            is safe in operation and backed up with ease.
          </p>
        </Card>
      </div>
      <div className="absolute top-[99rem] w-3/4 left-8 md:top-[68rem] md:left-[22rem] max-w-md lg:top-[50rem] lg:left-0 lg:pl-32 xl:top-[60rem]">
        <UndrawEngineers className="lg:w-3/4 xl:w-full" />
      </div>

      <div className="absolute top-[120rem] w-full md:top-[100rem]  md:flex md:justify-center lg:top-[75rem] xl:top-[85rem]">
        <WhyWorkWithUs />
      </div>
      <div className="absolute top-[142rem] md:top-[116rem]  w-full lg:top-[90rem] xl:top-[99rem]">
        <Footer />
      </div>
    </div>
  );
};

export default CloudComputing;
