import HeaderWhiteBackgroundImage from "../Components/HeaderWhiteBackgroundImage";
import Card from "../Components/card";
import Footer from "../Components/footer";
import WhyWorkWithUs from "../Components/whyWorkWithUs";
import { ReactComponent as Vectorservice1 } from "../asset/Vectorservice (1).svg";
import { ReactComponent as Vectorservice2 } from "../asset/Vectorservice (2).svg";
import { ReactComponent as Vectorservice3 } from "../asset/Vectorservice (3).svg";
import serviceHeaderImage1 from "../asset/capacitybuild.png";
import { ReactComponent as IconParkLight } from "../asset/cstreamline_industry-innovation-and-infrastructure.svg";
import { ReactComponent as UndrawEngineers } from "../asset/undraw_geniuses_9h9g 1.svg";
import { ReactComponent as Undraw } from "../asset/undraw_work_together_re_5yhn 1.svg";

const CapacityBuild = () => {
  return (
    <div className="z-50 overflow-hidden font-Montserrat ">
      <HeaderWhiteBackgroundImage
        src={serviceHeaderImage1}
        icon={<IconParkLight />}
        title={"Capacity Building"}
        description={
          "Empower your workforce to drive innovation, enhance productivity, and ensure long-term success in today's dynamic business environment."
        }
      />
      <div className="absolute top-[13rem] w-full h-max-content"></div>
      <Vectorservice1 className="absolute right-0 top-[32rem] w-4/5 max-w-md  lg:top-[15rem] xl:top-[34rem] xl:right-0" />

      <Vectorservice2 className="absolute top-0 w-1/2 md:top-[10rem]  max-w-md lg:top-[10rem] xl:top-[30rem] z-0" />
      <Vectorservice3 className="absolute left-0 w-3/4 top-[70rem] max-w-md md:top-[55rem] lg:top-[40rem] xl:top-[60rem]" />

      <div className="absolute top-[18rem]  flex justify-center md:top-[24rem] lg:top-[20rem] md:justify-start md:pl-24 lg:pl-14 xl:top-[34rem] xl:pl-36">
        <Card>
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="text-gray-700 ">
            Nidigit Solution offers capacity building and training services to
            companies to encourage sustainable and efficient development
            programs in Nigeria. On-going capacity evaluation and the building
            are essential to keep up with fast-evolving best practices and
            challenges in environmental and social performance. We provide these
            solutions ranging from one-day events to long-term programs. Our
            group of professionals have been providing technical and
            organizational capacity building training on an array of topics in
            various sectors to both public and private organization.
          </p>
        </Card>
      </div>
      <div className="lg:flex lg:justify-end">
        <Undraw className="absolute  w-3/4 md:w-2/3 lg:w-1/3 top-[46rem] md:top-[42rem] md:right-[20rem] lg:top-[22rem] xl:top-[34rem]  lg:right-16 " />
      </div>

      <div className="absolute tracking-tight top-[64rem]  flex justify-center md:top-[85rem] lg:top-[45rem] lg:justify-end lg:pr-24 xl:top-[65rem] xl:pr-32">
        <Card className="">
          {/* <h1 className="mb-2 text-xl font-bold">Card Title</h1> */}
          <p className="tracking-widest text-gray-700 ">
            Capacity-building or development is the procedure by which
            individuals and organizations acquire, enhance, and retain the
            tools, knowledge, skills, and other resources required to perform
            their jobs competently or to a higher capacity to acquire better
            results. Nidigit Solution strives to support growth, sustainability,
            and good impact of business and projects throughout the country. To
            accomplish that, we concentrate on creating and providing highly
            professional capacity building and training services to
            accelerators, incubators, NGOs, public and private organizations.
            Our capacity-building programs are based on the most innovative
            knowledge management and adult learning principles. We offer
            training for different sectors and topics like monitoring and
            evaluation, change management, policy, awareness-raising, project
            management, and more.
          </p>
        </Card>
      </div>
      <div className="absolute top-[104rem] w-3/4 left-8 md:top-[68rem] md:left-[22rem] max-w-md lg:top-[45rem] lg:left-0 lg:pl-32 xl:top-[66rem]">
        <UndrawEngineers className="lg:w-3/4 xl:w-full" />
      </div>

      <div className="absolute top-[120rem] w-full md:top-[103rem]  md:flex md:justify-center lg:top-[75rem] xl:top-[89rem]">
        <WhyWorkWithUs />
      </div>
      <div className="absolute top-[142rem] md:top-[119rem]  w-full lg:top-[90rem] xl:top-[99rem]">
        <Footer />
      </div>
    </div>
  );
};

export default CapacityBuild;
