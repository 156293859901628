const ContactUs = () => {
  return (
    <div className="flex h-76 contast-us bg-primary text-whitesmoke">
      <section className="flex flex-col items-center justify-center w-full">
        <p className="text-xs font-blacknpm md:text-2xl">
          Are you ready to accelerate your IT and bussiness growth
        </p>
        <div className="flex items-center justify-center h-screen py-4">
          <div className="w-24 h-0.5 rounded bg-gold md:w-40"></div>
        </div>
        <p className="py-5 text-sm font-medium md:text-xl">
          Partner with Nidigit today and get a free consultation
        </p>
        <a href="/contact">
          <button className="w-32 h-10 mt-4 rounded-full cursor-pointer font-small bg-whitesmoke hover:bg-blue-700 text-primary md:w-40 md:h-10">
            contact us
          </button>
        </a>
      </section>
    </div>
  );
};

export default ContactUs;
