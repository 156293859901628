import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { useState } from "react";
import Footer from "../Components/footer";
import Header from "../Components/header";
import serviceHeaderImage1 from "../asset/Group 16contact.jpg";
import { ReactComponent as Mail } from "../asset/Mail icon.svg";
import { ReactComponent as Phone } from "../asset/Phone icon.svg";
import map from "../asset/Rectangle.png";

const Contact_us = () => {
  const [message, setMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="contactus font-Montserrat">
      <div className="relative top-0 z-50 w-full px-12 py-2 bg-white shadow-lg nav xl:pt-4">
        <Header className="" />
      </div>
      <section className="relative w-full backgroundImage">
        <img
          data-src={serviceHeaderImage1}
          alt="belowheaderimage"
          className="w-full h-[44vh] xl:h-[75vh] lazyload"
        />
      </section>
      <section className="absolute top-16 md:top-24 md:left-24 xl:top-[6rem] xl:left-[10rem] overlayDescription lg:top-[10rem]">
        <div className="">
          <section className="flex items-center font-medium xl:py-2 ">
            {/* {icon} */}
            <div>
              <section className="px-6 xl:justify-center xl:max-w-max md:px-4">
                <p className="pt-2 font-medium tracking-tight text-whitesmoke xl:text-5xl md:text-3xl xl:pt-12 ">
                  Contact
                </p>
                <div className="flex py-1 jsutfy-start xl:justify-center xl:py-4">
                  <div className="h-0.5 rounded w-24 md:w-24 xl:w-44 bg-gold font-"></div>
                </div>
              </section>
              <p className="px-6 text-xs font-thin tracking-tight w-80 md:w-96 text-whitesmoke xl:pr-2 xl:tracking-wider xl:w-[28rem] xl:text-lg md:px-4 md:tracking-wider md:text-medium">
                Get in touch with us today and let's start transforming your
                business together!
              </p>
            </div>
          </section>
        </div>
      </section>

      <div className="md:flex md:flex-col xl:flex xl:flex-row xl:justify-start">
        <div className="flex justify-center contact relative top-[-5rem] md:top-[-15rem] xl:h-h-max ">
          <section className="w-4/5 shadow-xl h:max rounded-xl xl:w-1/2">
            <div className="items-center p-8 text-sm md:flex md:justify-center xl:flex-col">
              <p className="xl:text-xl md:font-medium">
                Do you have concerns or want more information about our
                services? Fill and submit the online form or reach out to us via
                phone call or mail.
              </p>

              <section className="flex flex-col py-8 md:justify-start md:w-full">
                <div className="flex my-4 phone">
                  <section>
                    <Phone className="w-8 h-10 md:w-16 md:h-16" />
                  </section>
                  <section className="pl-4 md:pt-3 xl:pr-12">
                    <p className="">Call us directly at </p>
                    <p>
                      <a
                        href="tel:+234 909 984 3423
"
                        className="hover:text-gold"
                      >
                        +234 909 984 3423
                      </a>
                    </p>
                  </section>
                </div>
                <div className="flex my-4 mail ">
                  <section>
                    <Mail className="w-8 h-10 md:w-16 md:h-16" />
                  </section>
                  <section className="pl-2 md:pt-3 xl:pr-12">
                    <p className="pl-2">Send us an email at </p>
                    <p>
                      <a
                        href="mailto:enquiries@nidigit.com
"
                        className="px-2 hover:text-gold"
                      >
                        enquiries@nidigit.com
                      </a>
                    </p>
                  </section>
                </div>
              </section>
            </div>
          </section>
        </div>
        {/* start */}
        <div className="flex items-center justify-center my-8 xl:my-0 bg-gray-100 relative top-[-2.5rem] md:top-[-12rem] xl:top-[-15rem] xl:w-3/5">
          <div className="w-4/5 ">
            <form className="p-6 my-6 bg-white rounded-lg shadow-lg xl:my-0">
              <div className="py-4 ">
                <label htmlFor="name" className="mb-2 text-sm text-gray-700 ">
                  Name:
                </label>
                <input
                  placeholder="Enter your name"
                  type="text"
                  id="name"
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="py-4 ">
                <label htmlFor="email" className="mb-2 text-sm text-gray-700 ">
                  Email:
                </label>
                <input
                  placeholder="Enter Your email Address"
                  type="email"
                  id="email"
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="py-4 ">
                <label
                  htmlFor="contact-number"
                  className="mb-2 text-sm text-gray-700 "
                >
                  Contact Number:
                </label>
                <input
                  placeholder="Enter Your Contact Number"
                  type="number"
                  id="contact-number"
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="py-4 ">
                <label
                  htmlFor="contact-number"
                  className="mb-2 text-sm text-gray-700 "
                >
                  Message:
                </label>
                <textarea
                  placeholder="Enter Message"
                  value={message}
                  onChange={handleChange}
                  className="w-full h-32 p-2 border rounded xl:h-48 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <button
                type="submit"
                className="w-32 h-10 mt-4 rounded-full cursor-pointer font-small bg-primary hover:bg-blue-700 text-whitesmoke md:w-40 md:h-10"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        {/* End */}
      </div>

      <div className="p-8 md:flex md:justify-center md:w-full">
        <img src={map} alt="maplocation" className="rounded-3xl xl:w-4/5" />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Contact_us;
