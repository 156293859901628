import Footer from "../Components/footer";
import ServiceComponent from "../Components/services";
const Services = () => {
  return (
    <div className="z-50 overflow-hidden font-Montserrat ">
      <section className="">
        <ServiceComponent />
      </section>
      <div className="absolute top-[130rem] md:top-[116rem]  w-full lg:top-[90rem] xl:top-[96rem]">
        <Footer />
      </div>
    </div>
  );
};

export default Services;
