import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useLocation } from "react-router-dom";
// import YourImage from "../asset/Group14.jpg";
import { ReactComponent as Logo } from "../asset/IMG-20240502-WA0016logo.svg";

const Header = () => {
  let currentPage;
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleMenu = () => {
    // slide out mobile menu
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  switch (location.pathname) {
    case "/":
      currentPage = "home";

      break;
    case "/about":
      currentPage = "about";
      break;
    case "/contact":
      currentPage = "contact";
      break;
    case "/service":
      currentPage = "service";
      break;
    default:
      currentPage = "unknown";
  }
  // DropDown
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    alert(`You selected: ${option}`);
  };

  // <div className="border border-gold text-whitesmoke absolute left-[6rem] w-full text-xs md:w-1/3 md:left-[30rem] xl:left-[65rem] xl:top-[3rem] xl:min-w-1/3  md:top-7 z-10 hidden p-8 shadow-lg sm:z-50 sm:top-0 bg-general top-8 group-hover:block"}>

  const createDropdown = (options) => (
    <div
      // ${
      //   isMenuOpen ? "absolute " : ""
      // }
      className={`absolute hidden w-full py-8 text-xs border shadow-lg border-gold lg:w-max text-whitesmoke md:w-1/3 md:top-8 0 bg-general top-6 lg:top-10 group-hover:block`}
    >
      {options.map((option, index) => (
        <div
          key={index}
          // onClick={() => handleOptionClick(option)}
          className="px-2 py-1 cursor-pointer hover:bg-gray-200 hover:text-gold"
        >
          <a href={`${Object.values(option)}`}>{Object.keys(option)}</a>
        </div>
      ))}
    </div>
  );
  // Array containing navigation items
  const navItems = [
    { id: 1, text: "About" },
    { id: 2, text: "Services" },
    { id: 3, text: "FAQs" },
    { id: 4, text: "Contact" },
  ];

  const mobileMenu = [
    { "IT Assurance": "/services/it-assurance" },
    {
      "Capacity Building and Training": "/services/capacity-building",
    },
    { "Mobility Service": "/services/mobility-service" },
    {
      "IT Outsourcing and Recruitment": "/services/it-outsourcing",
    },
    {
      "Technology Business Consultuing": "/services/tech-business",
    },
    { "Cloud Computing": "/services/cloud-computing" },
  ];

  return (
    <div
      className={`fixed top-0 left-0 z-50 w-full py-1 ${
        currentPage === "home" ? "bg:transparent" : "bg-white"
      }`}
    >
      {/* Nav start */}
      {/* className={`bg-white p-4 ${page === 'home' ? 'text-blue-500' : 'text-green-500'}`} */}
      {/* className={`flex justify-between ${currentPage === 'home' ? "bg:gold" : 'bg-primary'}`} */}
      <nav className="flex justify-between ">
        <div className="w-4/5 md:w-2/5  lg:w-[50%] pl-8 md:pl-16 xl:pl-20 pt-2">
          <a href="/">
            {" "}
            <Logo className="" />{" "}
          </a>
        </div>

        {/* Mobile Menu Starts */}
        <div
          onClick={toggleMenu}
          className="items-end block md:hidden pt-1.5 pr-6"
        >
          {isMenuOpen ? (
            <AiOutlineClose size={20} />
          ) : (
            <AiOutlineMenu size={20} />
          )}
        </div>
        {/* Mobile Menu End */}
        {/* Menu for other screens Starts */}
        <div className="relative hidden w-2/5 pl-4  lg:w-[50%] md:block md:w-3/5  md:pl-4 xl:flex xl:w-[38%]  xl:justify-start">
          <ul className="hidden md:flex text-whitesmoke xl:w-[75%] xl:justify-evenly md:pt-2 xl:pt-2 items-center">
            {navItems.map((item) => (
              <li
                key={item.id}
                className={`group  md:px-4 hover:text-gold  ${
                  item.id === 4
                    ? "bg-primary hover:bg-blue-700 text-whitesmoke   rounded-full xl:cursor-pointer xl:py-1 "
                    : "bg-gray-300"
                }
                 ${currentPage === "home" ? "text-whitesmoke" : "text-primary"}
                
                group`}
              >
                <a
                  href={item.id != 2 && `${`/${item.text.toLowerCase()}`}`}
                  className=""
                >
                  {item.text}
                  {item.id === 2 &&
                    createDropdown([
                      { "IT Assurance": "/services/it-assurance" },
                      {
                        "Capacity Building and Training":
                          "/services/capacity-building",
                      },
                      { "Mobility Service": "/services/mobility-service" },
                      {
                        "IT Outsourcing and Recruitment":
                          "/services/it-outsourcing",
                      },
                      {
                        "Technology Business Consultuing":
                          "/services/tech-business",
                      },
                      { "Cloud Computing": "/services/cloud-computing" },
                    ])}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* Menu for other Screen Ends */}
        {/*  */}
        <ul
          className={
            isMenuOpen
              ? "fixed  md:hidden left-0 top-14 w-[60%]  h-[100%] bg-primary text-whitesmoke ease-in-out duration-500 z-50"
              : "ease-in-out w-[60%] duration-500 fixed top-16 bottom-0 left-[-100%]"
          }
        >
          {/* Mobile Navigation Items */}
          {navItems.map((item) => (
            <li
              key={item.id}
              onClick={item.id === 2 ? () => toggleServices() : null}
              className={` ${
                item.id === 2 ? "relative" : ""
              }  p-4 duration-300 cursor-pointer  group hover:text-whitesmoke hover:bg-general hover:text-black"
          `}
            >
              <a
                href={item.id != 2 && `${`/${item.text.toLowerCase()}`}`}
                className="px-4 "
              >
                {item.text}
                {isServicesOpen && item?.id == 2 && (
                  <ul className="relative w-full">
                    {mobileMenu.map((option) => (
                      <li
                        key={item?.id}
                        className="py-2 ml-5 text-xs font-light tracking-tighter hover:text-gold"
                      >
                        <a
                          href={`${Object.values(option)}`}
                          className="text-xs"
                        >
                          {Object.keys(option)}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}

                {/* // <ul className="absolute p-2 mt-2 space-y-2 bg-gray-700 rounded shadow-lg">
                  //   <li>Service 1</li>
                  //   <li>Service 2</li>
                  //   <li>Service 3</li>
                  // </ul>
                // )} */}
                {/* {isServicesOpen &&
                  createDropdown([
                    { "IT Assurance": "/services/it-assurance" },
                    {
                      "Capacity Building and Training":
                        "/services/capacity-building",
                    },
                    { "Mobility Service": "/services/mobility-service" },
                    {
                      "IT Outsourcing and Recruitment":
                        "/services/it-outsourcing",
                    },
                    {
                      "Technology Business Consultuing":
                        "/services/tech-business",
                    },
                    { "Cloud Computing": "/services/cloud-computing" },
                  ])} */}
              </a>
            </li>
          ))}
        </ul>
        {/*  */}
      </nav>
      {/* Nav End */}
    </div>
  );
};

export default Header;
