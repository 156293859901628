import { ReactComponent as FaceBook } from "../asset/Group 2.svg";
import { ReactComponent as Twitter } from "../asset/Group 3.svg";
import { ReactComponent as IG } from "../asset/Group 4.svg";
import { ReactComponent as Logo } from "../asset/IMG-20240502-WA0016logo.svg";
import { ReactComponent as Mail } from "../asset/quill_mail.svg";
import { ReactComponent as Phone } from "../asset/solar_phone-linear.svg";

const Footer = () => {
  const navItems = [
    { id: 1, text: "About" },
    { id: 2, text: "FAQs" },
    { id: 3, text: "Contact" },
    // { id: 4, text: "Contact" },
    // they shouldnt be a dropdown in the footer, experience wise it doesnt make a lot of sense
  ];

  return (
    <div className="mt-6 footer h-max">
      <section className="md:flex md:justify-center topFooter ">
        <div className=" md:flex md:justify-center md:items-center md:w-4/5">
          <div className="items-end px-6 md:w-3/5 xl:flex xl:flex-col xl:w-1/3">
            <section className="">
              <Logo className="" />
              <p className="px-0 py-6 font-medium xl:w-64">
                We help businesses accomplish workplace transformation on a
                budget
              </p>
            </section>
          </div>
          <div className="flex items-center justify-center h-screen py-4 md:block ">
            <div className="h-0.5 rounded w-96 md:w-0.5 md:h-40 bg-gold"></div>
          </div>
          <div className="flex contact-in-footer justify-evenly py-9 md:w-4/5 text-general xl:w-2/5">
            <section className="contacts ">
              <h3 className="py-2 font-bold text-general">Contact</h3>
              <div className="flex items-center py-2 font-medium">
                <Mail />{" "}
                <a
                  href="mailto:enquiries@nidigit.com"
                  className="px-2 font-medium hover:text-gold "
                >
                  enquiries@nidigit.com
                </a>
              </div>

              <div className="flex items-center py-2">
                <Phone />{" "}
                <a
                  href="tel:+234 909 984 3423
"
                  className="px-2 hover:text-gold "
                >
                  +234 909 984 3423
                </a>
              </div>
            </section>
            <section className="links">
              <h3 className="py-2 font-bold text-general">Links</h3>
              <ul>
                {navItems.map((item) => (
                  <li
                    key={item.id}
                    className="py-2 font-medium cursor-pointer md:font-normal md:py-2"
                  >
                    <a
                      href={`${`/${item.text.toLowerCase()}`}`}
                      className="hover:text-gold"
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </section>
      <section className="flex items-center border cursor-pointer justify-evenly bottomFooter bg-primary">
        <div className="copyright">
          <p className="text-sm font-bold text-whitesmoke">
            Copyright © 2024 NiDigit
          </p>
        </div>
        <div className="flex socials">
          <div className="flex items-center py-1 font-semibold">
            <a href="#">
              {" "}
              <FaceBook />
            </a>
          </div>
          <div className="flex items-center py-1 font-semibold">
            <a href="#">
              {" "}
              <Twitter />
            </a>
          </div>
          <div className="flex items-center py-1 font-semibold">
            <a href="#">
              {" "}
              <IG />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
