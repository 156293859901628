import { useState } from "react";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  return (
    <nav className="p-4 bg-gray-800">
      <div className="container flex items-center justify-between mx-auto">
        <div className="text-lg font-bold">My Website</div>
        <div className="md:hidden">
          <button className=" focus:outline-none" onClick={toggleMenu}>
            ☰
          </button>
        </div>
        <ul className="hidden space-x-4 md:flex">
          <li>Home</li>
          <li>About</li>
          <li className="relative">
            <button onClick={toggleServices} className="focus:outline-none">
              Services
            </button>
            {isServicesOpen && (
              <ul className="absolute p-2 mt-2 space-y-2 bg-gray-700 rounded shadow-lg">
                <li>Service 1</li>
                <li>Service 2</li>
                <li>Service 3</li>
              </ul>
            )}
          </li>
          <li>Contact</li>
        </ul>
      </div>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="p-4 bg-gray-700 md:hidden">
          <ul className="space-y-2">
            <li>Home</li>
            <li>About</li>
            <li>
              <button
                onClick={toggleServices}
                className="w-full text-left focus:outline-none"
              >
                Services
              </button>
              {isServicesOpen && (
                <ul className="pl-4 mt-2 space-y-2">
                  <li>Service 1</li>
                  <li>Service 2</li>
                  <li>Service 3</li>
                </ul>
              )}
            </li>
            <li>Contact</li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
