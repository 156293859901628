import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import ContactUs from "../Components/contact-us";
import Footer from "../Components/footer";
import Header from "../Components/header";
import { ReactComponent as Server } from "../asset/Illustrationexpreesway.svg";
import { ReactComponent as WaveRight } from "../asset/Vector (1).svg";
import { ReactComponent as Vector2 } from "../asset/Vector (2).svg";
import { ReactComponent as WaveLeft } from "../asset/Vector.svg";
import { ReactComponent as CloudMonitoring } from "../asset/carbon_cloud-monitoring.svg";
import { ReactComponent as CloudServices } from "../asset/carbon_cloud-services.svg";
import { ReactComponent as FluentMdl } from "../asset/fluent-mdl2_recruitment-management.svg";
import friendlyceodesk from "../asset/friendly-ceo-desk 2.png";
import { ReactComponent as IconPark } from "../asset/icon-park-outline_protect.svg";
import { ReactComponent as MaterialSymbolLight } from "../asset/material-symbols-light_computer-outline.svg";
import { ReactComponent as MaterialSymbol } from "../asset/material-symbols_home-repair-service-outline.svg";
import { ReactComponent as MdiWorker } from "../asset/mdi_worker-outline.svg";
import { ReactComponent as PhbriefcasLight } from "../asset/ph_briefcase-light.svg";
import { ReactComponent as PhHandshake } from "../asset/ph_handshake.svg";
import { ReactComponent as SolarWalllet } from "../asset/solar_wallet-money-outline.svg";
import { ReactComponent as Streamline } from "../asset/streamline_industry-innovation-and-infrastructure.svg";

const Homepage = () => {
  const ourServices = [
    {
      id: 1,
      title: "IT Assurance",
      svg: <IconPark />,
      link: "/services/it-assurance",
      text: "Organizations need the best-in-class technology, technical specialists, and robust processes to encourage business owners to constantly advance and concentrate on the core business without compromising on security. That’s what makes IT security assurance essential.",
    },
    {
      id: 2,
      title: "Capacity Building and Training",
      svg: <Streamline />,
      link: "/services/capacity-building",
      text: "Organizations need the best-in-class technology, technical specialists, and robust processes to encourage business owners to constantly advance and concentrate on the core business without compromising on security. That’s what makes IT security assurance essential.",
    },
    {
      id: 3,
      title: "Mobility Service",
      svg: <MaterialSymbolLight />,
      link: "/services/mobility-service",
      text: "Organizations need the best-in-class technology, technical specialists, and robust processes to encourage business owners to constantly advance and concentrate on the core business without compromising on security. That’s what makes IT security assurance essential.",
    },
    {
      id: 4,
      title: "IT Outsourcing and Recruitment",
      svg: <FluentMdl />,
      link: "/services/it-outsourcing",
      text: "Organizations need the best-in-class technology, technical specialists, and robust processes to encourage business owners to constantly advance and concentrate on the core business without compromising on security. That’s what makes IT security assurance essential.",
    },
    {
      id: 5,
      title: "Technology Business Consultuing",
      svg: <PhbriefcasLight />,
      link: "/services/tech-business",
      text: "Organizations need the best-in-class technology, technical specialists, and robust processes to encourage business owners to constantly advance and concentrate on the core business without compromising on security. That’s what makes IT security assurance essential.",
    },
    {
      id: 6,
      title: "Cloud Computing",
      svg: <CloudServices />,
      link: "/services/cloud-computing",
      text: "Organizations need the best-in-class technology, technical specialists, and robust processes to encourage business owners to constantly advance and concentrate on the core business without compromising on security. That’s what makes IT security assurance essential.",
    },
  ];

  return (
    <div className="relative p-0 m-0 overflow-x-hidden font-Montserrat">
      {/* starting div */}
      <div className="bg-whitesmoke h-96 md:h-128 xl:h-128">
        <section className="z-10 flex justify-between">
          <div className="aspect-w-1 aspect-h-1 ">
            <WaveLeft className="md:hidden xl:block" />
          </div>
          <div className=" aspect-w-1 aspect-h-1">
            <WaveRight className="hidden md:block " />
          </div>
        </section>
        <section className="absolute w-full px-8 top-4 nav xl:pt-4 ">
          <div className="relative ">
            <Header />
          </div>

          <div className="flex h-40 pl-2 ">
            <div className="py-8 mt-8 text-general md:w-full md:h-40 md:mt-24 md:pl-20 xl:mt-10 xl:ml-8">
              <h3 className="py-2 text-3xl font-medium md:py-4 md:text-6xl xl:text-6xl xl:font-medium xl:tracking-widest">
                Take the <span className="text-secondary">Expressway</span>{" "}
              </h3>
              <h3 className="py-2 text-4xl font-medium xl:text-6xl xl:tracking-wider md:text-6xl">
                To <span className="text-secondary">Success</span>
              </h3>
              <a href="/about">
                <button className="w-32 h-10 mt-4 rounded-full font-small bg-primary hover:bg-blue-700 text-whitesmoke md:w-40 md:h-10">
                  Learn more
                </button>
              </a>
            </div>
            <div className="hidden xl:block xl:mt-20 xl:mr-44">
              <Server className="hidden xl:block" />
            </div>
          </div>
        </section>
      </div>
      <div className="flex flex-col thingswedo">
        <h3 className="py-4 text-2xl font-semibold text-center text-general md:font-extrabold md:text-4xl md:py-8 xl:tracking-widest">
          Things We Do Better
        </h3>
        <div className="flex items-center justify-center h-screen py-4">
          <div className="w-40 h-0.5 rounded bg-gold xl:w-60"></div>
        </div>
        <div className="flex flex-col py-12 xl:flex-row ">
          <section className="px-8 picuture md:px-44 xl:w-1/2 xl:px-18 ">
            <div className="relative h-96 md:h-128 ">
              <div className="absolute top-0 left-0 w-32 h-24 bg-primary"></div>
              <div className="absolute bottom-0 left-0 w-32 h-24 bg-general"></div>
              <img
                className="absolute w-4/5 ml-10 rounded lazyload h-96 md:h-128 py-7 xl:w-full"
                data-src={friendlyceodesk}
                alt="Overlay "
              />
            </div>
          </section>
          <section className="grid items-center grid-cols-1 gap-6 px-5 py-4 mt-8 xl:w-2/3 md:grid-cols-2 md:gap-8 whatwedo justify-items-center ">
            <section className="w-64 text-general ">
              <div className="flex items-center py-3 font-semibold">
                <Vector2 />
                <span className="ml-2 md:text-xl md:font-medium">
                  {" "}
                  Fast response
                </span>
              </div>

              <p className="md:font-medium">
                We guarantee a 30minute response for all your network
                emergencies
              </p>
            </section>
            <section className="w-64 text-general ">
              {/* <p className="font-semibold"> */}
              <div className="flex items-center py-3 font-semibold">
                <CloudMonitoring />
                <span className="ml-2 md:text-xl md:font-medium">
                  {" "}
                  24/7 Monitoring
                </span>
              </div>
              <p className="md:font-medium">
                We proactively monitor and handle your system to guarantee
                optimal uptime.
              </p>
            </section>{" "}
            <section className="w-64 text-general ">
              <div className="flex items-center py-3 font-semibold">
                <SolarWalllet />
                <span className="ml-2 md:text-xl md:font-medium">
                  {" "}
                  Fixed budget
                </span>
              </div>
              <p className="md:font-medium">
                We offer flat-rate monthly pricing and unlimited remote/on-site
                support.
              </p>
            </section>{" "}
            <section className="w-64 text-general ">
              <div className="flex items-center py-3 font-semibold">
                <MaterialSymbol />
                <span className="ml-2 md:text-xl md:font-medium">
                  {" "}
                  Full Accountability
                </span>
              </div>
              <p className="md:font-medium">
                We provide a no-risk 100% money-back guarantee to assure
                customer satisfaction.
              </p>
            </section>{" "}
            <section className="w-64 text-general ">
              <div className="flex items-center py-3 font-semibold">
                <MdiWorker />
                <span className="ml-2 md:text-xl md:font-medium">
                  {" "}
                  Seasoned Professionals
                </span>
              </div>
              <p className="md:font-medium">
                We have the experience of an entire IT team for only a fraction
                of the price.
              </p>
            </section>{" "}
            <section className="w-64 text-general ">
              <div className="flex items-center py-3 font-semibold">
                <PhHandshake />
                <span className="ml-1 md:text-md md:font-medium ">
                  Dependable & Reputable service
                </span>
              </div>
              <p className="md:font-medium">
                We are trusted and backed by prestigious organizations in
                Nigeria
              </p>
            </section>
          </section>
        </div>
      </div>
      <div className="pb-20 ourServices xl:py-10">
        <h3 className="py-2 text-4xl text-center text-general md:text-6xl">
          Our Services
        </h3>
        <div className="flex items-center justify-center h-screen py-4">
          <div className="w-40 h-0.5 rounded bg-gold"></div>
        </div>
        <div className=" xl:flex xl:justify-center">
          <section className="grid grid-cols-1 mt-20 justify-items-center gap-y-16 md:grid-cols-2 xl:grid-cols-3 xl:mt-8 xl:w-4/5">
            {ourServices.map((item) => (
              <div className="pb-8 overflow-hidden shadow-xl w-80 h-max rounded-xl ">
                <div className="p-4">
                  <div className="flex justify-center py-10 xl:py-5">
                    {item.svg}
                  </div>
                  <div className="mb-2 text-xl font-bold md:font-black md:text-sm ">
                    {item.title}
                  </div>
                  <p className="text-base text-gray-700 md:text-sm ">
                    {item.text}
                  </p>
                </div>
                <div className="px-6 pt-4 pb-2 text-center">
                  <a
                    href={`${`${item.link}`}`}
                    className="font-medium text-general "
                  >
                    Read More
                  </a>
                </div>
              </div>
            ))}
          </section>
        </div>
      </div>
      <div className="contactus">
        <ContactUs />
      </div>
      <div className="footer">
        <Footer />
      </div>
      {/* ending div */}
    </div>
  );
};

export default Homepage;
